.w-block1{
    @apply max-w-[390px]
}
.title-level1{
    @apply font-extrabold text-[34px] uppercase leading-[37px] w-[350px]
}
.text-level1{
    @apply font-light text-[19px] uppercase
}

.label-level2{
    @apply uppercase font-extrabold text-[12px]
}
.title-level2{
    @apply font-bold text-[18px] leading-[24px]
}
.title-level2{
    color: var(--bg-inverted);
}
.text-level2{
    @apply font-light mt-[5px] text-[13px]
}

.title-resource{
    @apply text-[14px] italic
}

.space-between-blocks{
    @apply space-y-16
}
.space-between-same-block{
    @apply space-y-8
}
.space-between-main-blocks{
    @apply space-y-[120px]
}
.bg-container{
    background-color: #ededed;
}

